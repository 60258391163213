import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import DemoPage from "../../../components/ColoradoPortedComps/DemoPage";
import LocalSlider from "../../../components/ColoradoPortedComps/LocalSlider";
// import PartnerLogos from "../../../components/ColoradoPortedComps/PartnerLogos";
import { georgiaTestimonialsData } from "../../../data/local/georgia-data";

import ogImage from "../../../images/global_assets/og-image.png";

const Demo = () => {
  return (
    <Layout phoneNumber="+18145669337" phoneText="(814) 566-9337">
      <SEO
        title="SpotOn Pennsylvania| Restaurant Point Of Sale"
        description="SpotOn Pennsylvania makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
      />
      <DemoPage
        formId="0712ebab-0e44-4b55-b733-7946f1451296"
        thankYouPath="/local/pennsylvania/thank-you"
      />
      <br />
      <br />
      <br />
      <br />
      {/* <PartnerLogos /> */}
      <LocalSlider testimonialsData={georgiaTestimonialsData} />
    </Layout>
  );
};

export default Demo;
